#loading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 98vw;
  height: 100vh !important;
  background-image: url("https://assets.stylori.com/images/static/loadingimg.gif");
  background-repeat: no-repeat;
  background-position: center;
}
#loadingsilver {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 98vw;
  height: 100vh !important;
  background-image: url("https://styloribaseimages.s3.ap-south-1.amazonaws.com/base_images/loaderimagesilver.gif");
  background-repeat: no-repeat;
  background-position: center;
}
.overall-loader {
  /* margin-left: 40% !important;
    margin-top: 20% !important; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  width: 100%;
  height: 100vh !important;
  background-size: 150% 150%;
  position: fixed;
  top: 0;
  background-color: #ffffff !important;
  z-index: 99999999;
}

.overall-loader1 {
  width: 60px !important;
  height: 60px !important;
  margin: auto !important;
  object-fit: scale-down;
}

#sillverloading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000000000000000000 !important;
  width: 100% !important;
  height: 100% !important;
  background-image: url("https://styloriimages.s3.ap-south-1.amazonaws.com/Banners/Stylori+Silver/Menu+images/Stylori+Silver+N+logo.png");
  background-repeat: no-repeat;
  background-size: 8% 16%;
  background-position: center;
}

